import type { UseFetchOptions } from "nuxt/app";
import { Method } from "~/enums";
import { useEtcStore } from "~/store/etcStore";
import { useUserStore } from "~/store/userStore";
import { defu } from "defu";

export async function useCustomFetch<T>(url: string | (() => string), options: UseFetchOptions<T> = {}) {
	// const config = useRuntimeConfig();
	const userStore = useUserStore();
	const etcStore = useEtcStore();
	const userToken = useCookie("userToken");
  const route = useRoute();

  const abortController = new AbortController();

	// const router = useRouter();
	// const userToken = useCookie("userToken");
	// const defaults: UseFetchOptions<T> = {
	// 	baseURL: config.public.authOrigin,
	// 	// cache request
	// 	key: url as string,
	// 	method: options.method ?? "GET",
	// 	query: options.query,
	// 	params: options.params,
	// 	body: options.body,

	// 	// set user token if connected
	// 	headers: userStore.apiTokenInfo ? { Authorization: `Bearer ${userStore.apiTokenInfo.accessToken}` } : {},
	// 	// headers: userToken.value ? { Authorization: `Bearer ${userToken.value}` } : {},

	// 	async onRequest(_ctx) {
	// 		etcStore.setLoading();
	// 		if (!userToken.value) {
	// 			const result = await userStore.refreshToken();
	// 			_ctx.options.headers = { Authorization: `Bearer ${userStore.apiTokenInfo.accessToken}` };
	// 		}
	// 	},

	// 	onResponse(_ctx) {},
	// };

	// const params = defu(options, defaults);
  // console.log(url);
  // userStore.accessTokenClear();


  let accessToken = userStore.apiTokenInfo ? userStore.apiTokenInfo.accessToken : '';


  if(!accessToken || accessToken === '' ){
    await userStore.refreshToken();
    accessToken = userStore.apiTokenInfo.accessToken;
  }

	let result = await useFetch(url, {
		method: options.method ?? "GET",
		headers: { Authorization: `Bearer ${accessToken}` },
		query: options.query,
    params: options.params,
    body: options.body,
    // onRequest: async (_ctx) => {
    //   if(!route.fullPath.includes("/newDashboard")){


    //     console.log("Bearers :::", _ctx.options.headers.Authorization.split('Bearer ')[1]);

    //     if (!userToken.value || !userStore.apiTokenInfo || !userStore.apiTokenInfo.accessToken) {
    //       let test = await userStore.refreshToken();
    //       _ctx.options.headers = { Authorization: `Bearer ${userStore.apiTokenInfo.accessToken}` };
    //     }
    //   }
		// },
    // onRequestError: (_ctx) => {
    //   console.log('::: request error :::', _ctx);
    // },
    // onResponse: (_ctx) => {
    //   console.log('::: response ::: ', _ctx)
    // },
    // onResponseError: (_ctx) => {
    //   console.log('::: response error :::', _ctx)
    // }
	});


  // let result = await useAsyncData('callApi', () => $fetch(url as string, {
	// 	method: options.method ?? "GET",
	// 	headers: { Authorization: `Bearer ${userStore.apiTokenInfo.accessToken}` },
	// 	query: options.query,
  //   params: options.params,
  //   body: options.body,
	// 	onRequest: async (_ctx) => {
	// 		etcStore.setLoading();
	// 		if (!userToken.value) {
	// 			const result = await userStore.refreshToken();
	// 			_ctx.options.headers = { Authorization: `Bearer ${userStore.apiTokenInfo.accessToken}` };
	// 		}
	// 	},
	// }));


	// let result = ;
	return result;
}
