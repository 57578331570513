import { CompanyKind, Method, PermissionType, UserAuthGroup, UserType } from "@/enums";
import CommonUtils from "@/utils/common-utils";
import { ApiToken } from "@/models/core-types";
import { AppPushDto, UserInfo, UserPageInfo } from "@/models/dto-types";
import { useLocalStorage } from "@vueuse/core";
import { PermissionStatus } from "@/enums";

export const useUserStore = defineStore("userStore", {
	state: () => ({
		loginUserInfo: useLocalStorage("loginUserInfo", {} as UserInfo | null),
		apiTokenInfo: useLocalStorage("apiTokenInfo",  {} as ApiToken),
		loginUserOwnerInfo: useLocalStorage("loginUserOwnerInfo", {} as UserInfo),
    loginUserType: useLocalStorage("loginUserType", {} as string),
		users: useLocalStorage("users", [] as UserInfo[]),
		setting: useLocalStorage("setting", {} as AppPushDto),
		userSeq: 0,
		isBioLock: false,
		isCameraPermission: false,
		isMediaPermission: false,
		termInfo: [] as any[],
		isFirst: useLocalStorage("isFirst", true),
		appFirstIntro: false,
		firstLoginToken: "",
	}),
	// hydrate(state, initialState) {
	// 	state.loginUserInfo = useLocalStorage("loginUserInfo", initialState.loginUserInfo);
	// 	state.apiTokenInfo = useLocalStorage("apiTokenInfo", initialState.apiTokenInfo);
	// 	state.users = useLocalStorage("users", initialState.users);
	// 	state.setting = useLocalStorage("setting", initialState.setting);
	// },
	getters: {
		getUserType() {
			let userType = UserType.Owner;

			switch (this.loginUserInfo?.authGroupSeq) {
				case UserAuthGroup.Admin:
					userType = UserType.Admin;
					break;
				case UserAuthGroup.Contractor:
				case UserAuthGroup.SafetyManager:
				case UserAuthGroup.HostBizOperator:
        case UserAuthGroup.RtuManufact:
				case UserAuthGroup.PrivateBizOperator:
					userType = UserType.Manager;
					break;
				default:
					userType = UserType.Owner;
					break;
			}

			return userType;
		},
    getUserAuthType(){
      return this.loginUserInfo?.authGroupSeq as UserAuthGroup;
    },
    getUserAdmin(){
      console.log(this.loginUserInfo)

      return this.loginUserInfo.isCompAdminManager as string;
    }
	},
	actions: {
		setIsFirstIntro(value: boolean) {
			this.appFirstIntro = value;
		},
		async getUserInfo(userSeq: number) {
			const res = await useCustomFetch<UserInfo>(`/api/v1/users/${userSeq}`, { method: Method.GET });
			// (res.data.value as UserInfo).userId = "1";
			this.loginUserInfo = res.data.value as UserInfo;
			return this.loginUserInfo;
		},
		/**
		 * id, password로 로그인
		 * @param userId // 유저 ID
		 * @param password // 유저 PASSWORD
		 * @returns
		 */
		async login(userId: string, password: string, deviceInfo: string | undefined = undefined) {
			let header = undefined;
			if (deviceInfo) {
        window.localStorage.setItem("userDeviceInfo", deviceInfo);
				header = JSON.parse(deviceInfo);
			}

      let res;

			res = await useFetch(`/api/v1/signin`, {
				headers: header,
				method: Method.POST,
				params: { userId: userId, password: password },
				onResponse: (ctx) => {
					if (ctx.response.headers.get("Change-Pass-Token")) {
						this.firstLoginToken = ctx.response.headers.get("Change-Pass-Token") as string;
					}
				},
			});

			if (res.error.value !== null) {
				return res.error.value;
			}
			// console.log(res);

			this.apiTokenInfo = res.data.value as ApiToken;

			let time = new Date().getTime() + this.apiTokenInfo.refreshTokenExpiresIn * 1000;
			// let time = new Date().getTime() + 5000;
			let expireDate = new Date(time);


			useCookie("userToken", { expires: expireDate }).value = this.apiTokenInfo.refreshToken;

      // console.log(this.apiTokenInfo.refreshToken, useCookie('userToken').value)
			// const res = await useAsyncData(() => $fetch("/api/v1/signin", { method: Method.POST, params: { userId: userId, password: password } }));
			// this.apiTokenInfo = res.data.value as ApiToken;
			// return res;

			// 엑세스토큰 만료시간 설정
			if (this.apiTokenInfo.accessToken) {
				setTimeout(() => {
					this.apiTokenInfo.accessToken = "";
				}, this.apiTokenInfo.expiresIn * 1000);
			}

			return res.data.value as ApiToken;
		},
		async logout() {
			// document.cookie = ""
			let userToken = useCookie("userToken");
			const router = useRouter();
			userToken.value = null;

			localStorage.removeItem("autoLo.gin");

			this.loginUserInfo = null;

			// router.replace("/");
		},
		async searchUser(userSeq: number) {
			const res = await useCustomFetch<UserInfo>(`/api/v1/users/${userSeq}`);

			return res.data.value as UserInfo;
		},
		async getUsers(
			pageNo: number,
			pageSize: number,
			userType: string,
			authGroupSeq: number,			
			userId: string | undefined = undefined,
			email: string | undefined = undefined,
			userName: string | undefined = undefined,
			accountStatus: string | undefined = undefined
		) {
			const res = await useCustomFetch<UserPageInfo>("/api/v1/users", { method: Method.GET, query: { pageNo, pageSize, userType, authGroupSeq, userId, email, userName, accountStatus } });
			if (CommonUtils.isNullOrEmpty(res.data.value)) {
				this.users = [];
				return [];
			}

			this.users = res.data.value.content;

			this.loginUserOwnerInfo = this.users.find((v) => v.userSeq === this.loginUserInfo?.userSeq) as UserInfo;
			return res.data.value;
		},

		async refreshToken() {
			let userToken = useCookie("userToken");
			const router = useRouter();
      const route = useRoute();
			// if (!this.apiTokenInfo || !this.apiTokenInfo.accessToken) {
			// 	userToken = useCookie("userToken").value;
			// } else {
			// 	userToken = this.apiTokenInfo.refreshToken;
			// }

			// const userToken = useCookie("userToken");

      console.log(":::: token refresh ::::");
      console.log(this.apiTokenInfo);


			if (CommonUtils.isNullOrEmpty(userToken.value)) {
      alert("토큰이 만료되었습니다. 다시 로그인해주세요.");

				// window.localStorage.clear();
				// window.location.assign("/mobile/user/login");
				localStorage.removeItem("autoLogin");

        if(route.fullPath.includes('toc')){
          router.replace("/toc/user/login");
        } else {
          router.replace("/mobile/user/login");
        }

        return;
			}

			let refreshResult = await useFetch("/api/v1/signin/update-token", { method: Method.POST, query: { token: userToken.value } });
      this.apiTokenInfo = refreshResult.data.value as ApiToken;

      if(!this.apiTokenInfo){
        alert("토큰이 만료되었습니다. 다시 로그인해주세요.");

				// window.localStorage.clear();
				// window.location.assign("/mobile/user/login");
				localStorage.removeItem("autoLogin");

        if(route.fullPath.includes('toc')){
          router.replace("/toc/user/login");
        } else {
          router.replace("/mobile/user/login");
        }

        return;
      }

    
      // this.apiTokenInfo = refreshResult.data.value as A;
      // console.log(this.apiTokenInfo);
			let time = new Date().getTime() + this.apiTokenInfo.refreshTokenExpiresIn * 1000;
			let expireDate = new Date(time);

			// 엑세스 토큰을 새로 받아왔기 때문에 다시 만료시간 설정
			useCookie("userToken", { expires: expireDate }).value = this.apiTokenInfo.refreshToken;

			setTimeout(() => {
				this.apiTokenInfo.accessToken = "";
			}, this.apiTokenInfo.expiresIn * 1000);



			await this.getUserInfo((refreshResult.data.value as ApiToken).userSeq);


			return refreshResult;
		},

		async getUsersByToken() {
			const res = await useFetch<UserPageInfo>("/api/v1/users", {
				method: Method.GET,
				query: { pageNo: 1, pageSize: 10 },
				headers: { Accept: "application/json", Authorization: this.apiTokenInfo.accessToken },
			});

			if (CommonUtils.isNullOrEmpty(res.data.value)) {
				this.users = [];
				return [];
			}

			this.users = res.data.value.content;
			return res.data.value;
		},

		async getSession() {
			const res = await useFetch("/api/v1/signin/session", { method: Method.POST });
		},

		async signOut(userId: number) {
			const res = await useFetch(`/api/v1/users/${userId}/logout`, { method: Method.POST });
		},

		async disAgreeTerm(userSeq: number) {
			const res = await useCustomFetch("/api/v1/app/terms/dis-agree", { method: Method.GET, query: { userSeq: userSeq } });

			return res;
		},
    
    accessTokenClear(){
      this.apiTokenInfo = {} as ApiToken;
      // let userToken = useCookie("userToken");
      // userToken.value = null;
    },

		/**
		 * 비밀번호 변경
		 * @param userSeq // 유저 seq number
		 * @param originPass // 기존 비밀번호
		 * @param changePass // 변경할 비밀번호
		 * @returns
		 */
		async changePassword(userSeq: number, originPass: string, changePass: string) {
      if((!this.firstLoginToken && !this.apiTokenInfo && !this.apiTokenInfo.accessToken)) this.refreshToken();


			const res = await useFetch(`/api/v1/users/${userSeq}/change-pass`, {
				headers: this.firstLoginToken ? { Authorization: `Bearer ${this.firstLoginToken}` } : { Authorization: `Bearer ${this.apiTokenInfo.accessToken}` },
				method: Method.PUT,
				body: { newPasswd: changePass, passwd: originPass },
			});

			return res;
		},

		async getAppPush(userSeq: number) {
			const res = await useCustomFetch<AppPushDto>(`/api/v1/users/${userSeq}/alarms`, { method: Method.GET });

			this.setting = res.data.value as AppPushDto;

			return this.setting;
		},
		async userTerms() {
			const res = await useFetch(`/api/v1/m/terms`, { method: Method.GET });
			this.termInfo = res.data.value as any[];
			return res.data.value;
		},
		async getUserSeq(userId: string, userPwd: string) {
			const res = await useFetch("/api/v1/user/seq", { method: Method.GET, query: { userId: userId, password: userPwd } });
			this.userSeq = res.data.value as number;
			return res.data.value;
		},
		async setUserTerms(userSeq: number, isAgree: string, termId: number) {
			const res = await useFetch(`/api/v1/terms-agrees`, {
				headers: this.firstLoginToken ? { Authorization: `Bearer ${this.firstLoginToken}` } : { Authorization: `Bearer ${this.apiTokenInfo.accessToken}` },
				method: Method.POST,
				body: [{ isAgree: isAgree, termId: termId }],
			});
			return res.data.value;
		},
		setBioLock(isBioLock: boolean) {
			this.isBioLock = isBioLock;
		},

		setPermission(permissionType: PermissionType, permissionStatus: PermissionStatus) {
			const isPermission = permissionStatus === PermissionStatus.Granted || permissionStatus === PermissionStatus.Restricted || permissionStatus === PermissionStatus.Limited;

			switch (permissionType) {
				case PermissionType.Camera:
					this.isCameraPermission = isPermission;
					break;
				case PermissionType.Media:
					this.isMediaPermission = isPermission;
					break;
			}
		},
		async findUserId(userType: string, userName: string, mobile: string) {
			const res = await useCustomFetch(`/api/v1/user/find-id`, { method: Method.GET, query: { userType: userType, userName: userName, mobile: mobile } });
      
      return res.data.value;
		},
		async getResetPassPin(userId: string, userName: string, email: string) {
			const res = await useCustomFetch(`/api/v1/user/reset-pass-pin`, { method: Method.GET, query: { userId: userId, userName: userName, email: email } });
			return res.data.value;
		},
		async resetPassword(pinNum: string) {
			const res = await useCustomFetch(`/api/v1/user/reset-pass`, { method: Method.GET, query: { pinNum: pinNum } });
			return res.data.value;
		},
		async changeUserInfo(userSeq: number, email: string, mobile: string, userName: string, phone: string) {
			const res = await useCustomFetch<UserInfo>(`/api/v1/users/${userSeq}`, { method: Method.PUT, body: { email: email, mobile: mobile, name: userName, phone: phone } });
			let result = res.data;

      this.getUserInfo(userSeq);

			// this.loginUserInfo = result.value as UserInfo;

			return res;
		},
		async userDelete(userSeq: number) {
			const res = await useCustomFetch(`/api/v1/users/${userSeq}`, { method: Method.DELETE });
			return res;
		},
		async setUser(
			accountStatus: string | null,
			authGroupSeq: number,
			email: string | null,
			mobile: string | null,
			phone: string | null,
			regId: string | null,
			userId: string | null,
			userName: string | null,
			userPassword: string,			
		) {
			const res = await useCustomFetch(`/api/v1/users`, { method: Method.POST, body: { accountStatus, authGroupSeq, email, mobile, phone, regId, userId, userName, userPassword, } });
			this.users.push(res.data.value as UserInfo);
			return res.data.value;
		},
	},
});
