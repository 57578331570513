import { EngSource, Method } from "~/enums";
import { AlarmInfo, EngSourcesInSiteInfo, MonitoringByAllInfo, MonitoringBySourceInfo, PowerProductionInfo, ProductionInfoByOwner, SiteDetailInfo } from "~/models/dto-types";
import { useCommonCodeStore } from "./commonCodeStore";
import { useUserStore } from "./userStore";
import { useLocalStorage } from "@vueuse/core";

interface siteDataForm {
	date: string;
	real: number;
	predict: number;
}

interface SiteForm {
	siteSeq: number;
	siteName: string;
	siteAddr: string;
	siteDetailAddr: string;
	siteEquipInfo: [];
	siteSiGunGu: string;
}

export const useSiteStore = defineStore("siteStore", {
	state: () => ({
		siteSeq: useLocalStorage("siteSeq", -1 as number),
		siteName: useLocalStorage("siteName", "test" as string),
		selectedSite: useLocalStorage("selectedSite", {} as any),		
    firstSelectSite: useLocalStorage("firstSelectSite", {} as any),
		siteDatas: useLocalStorage("siteDatas", {
			monitoring: {} as MonitoringByAllInfo,
		}),
		siteEngSources: useLocalStorage("siteEngSource", {} as EngSourcesInSiteInfo),
		siteAddr: useLocalStorage("siteAddr", "" as string),
		siteSiGunGu: useLocalStorage("siteSiGunGu", "" as string),
		siteList: useLocalStorage("siteList", [] as string[]),
		selectCityCode: useLocalStorage("selectCityCode", "" as string),
		selectCityName: useLocalStorage("selectCityName", "" as string),
		selectSigunguCode: useLocalStorage("selectSigunguCode", "" as string),
		selectSigunguName: useLocalStorage("selectSigunguName", "" as string),
		selectDongCode: useLocalStorage("selectDongCode", "" as string),
		selectDongName: useLocalStorage("selectDongName", "" as string),
	}),
	// hydrate(state, initialState) {
	// 	state.siteSeq = useLocalStorage("siteSeq", initialState.siteSeq);
	// 	state.siteName = useLocalStorage("siteName", initialState.siteName);
	// 	state.selectedSite = useLocalStorage("selectedSite", initialState.selectedSite);
	// 	state.prodSitesStatus = useLocalStorage("prodSitesStatus", initialState.prodSitesStatus);
	// 	state.siteDatas = useLocalStorage("siteDatas", initialState.siteDatas);
	// 	state.siteEngSources = useLocalStorage("siteEngSources", initialState.siteEngSources);
	// 	state.siteAddr = useLocalStorage("siteAddr", initialState.siteAddr);
	// 	state.siteSiGunGu = useLocalStorage("siteSiGunGu", initialState.siteSiGunGu);
	// 	state.siteList = useLocalStorage("siteList", initialState.siteList);
	// 	state.selectCityCode = useLocalStorage("selectCityCode", initialState.selectCityCode);
	// 	state.selectCityName = useLocalStorage("selectCityName", initialState.selectCityName);
	// 	state.selectSigunguCode = useLocalStorage("selectSigunguCode", initialState.selectSigunguCode);
	// 	state.selectSigunguName = useLocalStorage("selectSigunguName", initialState.selectSigunguName);
	// 	state.selectDongCode = useLocalStorage("selectDongCode", initialState.selectDongCode);
	//   state.selectDongName = useLocalStorage("selectDongName", initialState.selectDongName);
	// },
	actions: {
		async selectSite(ownerSeq:number, siteName: string) {
			const res = await useCustomFetch(`/api/v1/site`, { method: Method.GET, query: { ownerSeq: ownerSeq, siteName: siteName } });
			this.selectedSite = res.data.value;
			return res.data.value;
		},
		setSiteName(name: string) {
			this.siteName = name;
		},
		async getSiteList() {
			this.siteList = [];

			const res = await useCustomFetch<string[]>(`/api/v1/m/sites`, { method: Method.GET });
			this.siteList = res.data.value as string[];

			return res.data.value as string[];
		},
		async getSiteMonitoring( siteName: string | undefined = undefined) {
			const res = await useCustomFetch<MonitoringByAllInfo>(`/api/v1/monitor/all`, { method: Method.GET, query: { siteName: siteName } });
			this.siteDatas.monitoring = res.data.value as MonitoringByAllInfo;
			return res.data.value as MonitoringByAllInfo;
		},
		async getSiteEngSource(siteName: string) {
			const res = await useCustomFetch<EngSourcesInSiteInfo>(`/api/v1/sites/eng-sources`, { method: Method.GET, query: { siteName: siteName } });
			this.siteEngSources = res.data.value as EngSourcesInSiteInfo;

			return this.siteEngSources;
		},
		async getSiteMonitoringEngSource(engSource: string, siteName: string | undefined = undefined, cid: string | undefined = undefined) {
      const res = await useCustomFetch<MonitoringBySourceInfo>(`/api/v1/monitor/by-eng-source`, { method: Method.GET, query: { engSource, cid, siteName } });
			return res.data.value as MonitoringBySourceInfo;

			// let token = useCookie("userToken");
			// let newToken = null;
			// if (!token.value) {
			// 	newToken = await useUserStore().refreshToken();
			// }

			// const res = await useFetch<MonitoringBySourceInfo>(`/api/v1/users/${userSeq}/monitorings/by-eng-source`, {
			// 	headers: { Authorization: `Bearer ${useUserStore().apiTokenInfo.accessToken}` },
			// 	query: { engSource },
			// });
			// console.log(res.data);
			// return res.data.value as MonitoringBySourceInfo;

			// const res = await axios.get(`/api/v1/users/${userSeq}/monitorings/by-eng-source`, { headers: { Authorization: `Bearer ${useUserStore().apiTokenInfo.accessToken}` }, params: { engSource } });
			// console.log(res);
			// return res.data as MonitoringBySourceInfo;
		},
		// async allProdSiteStatus(userSeq: rm number, cityCode: string | undefined = undefined, sigunguCode: string | undefined = undefined, dongCode: string | undefined = undefined) {

		// 	let citycode = cityCode !== "" && cityCode !== undefined && cityCode !== null ? cityCode : undefined;
		// 	let sigungucode = sigunguCode !== "" && sigunguCode !== undefined && sigunguCode !== null ? sigunguCode : undefined;
		// 	let dongcode = dongCode !== "" && dongCode !== undefined && dongCode 2== null ? dongCode : undefined;

		// 	const res = await useCustomFetch(`/api/v1/users/${userSeq}/monitorings/prod-sites-status`, { query: { cityCode: citycode, sigunguCode: sigungucode, dongCode: dongcode } });
		// 	this.prodSitesStatus = res.data.value as ProEquipsStatusInfo;

		// 	this.prodSitesStatus.regionSummaries.push({
		// 		cityCode: "11",
		// 		cityName: "서울특별시",
		// 		sigunguCode: "740",
		// 		sigunguName: "강동구",
		// 		dongCode: "1174010100",
		// 		dongName: "서울특별시 강동구 명일동",
		// 		latitude: 37.666535,
		// 		longitude: 126.8779692,
		// 		siteCount: 3,
		// 	});
		// 	return this.prodSitesStatus;
		// },
		setSelectCityCode(code: string, name: string) {
			this.selectCityCode = code;
			this.selectCityName = name;
			// const ownerOrManagerSeq = useUserStore().loginUserInfo.userSeq as number;

			// this.allProdSiteStatus(ownerOrManagerSeq, this.selectCityCode);
		},
		setSelectSigunguCode(code: string, name: string) {
			this.selectSigunguCode = code;
			this.selectSigunguName = name;
			// const ownerOrManagerSeq = useUserStore().loginUserInfo.userSeq as number;

			// this.allProdSiteStatus(ownerOrManagerSeq, this.selectCityCode, this.selectSigunguCode);
		},
		setSelectDongCode(code: string, name: string) {
			this.selectDongCode = code;
			this.selectDongName = name.split(" ")[name.split(" ").length - 1];
			// const ownerOrManagerSeq = useUserStore().loginUserInfo.userSeq as number;

			// this.allProdSiteStatus(ownerOrManagerSeq, this.selectCityCode, this.selectSigunguCode, this.selectDongCode);
		},
		generateData(type: string) {
			let tmpData = [] as siteDataForm[];

			let dataList1 = [0, 0, 0, 0, 0, 0, 9, 104.7, 287.2, 472.3, 611.6, 694.7, 718.9, 718.2, 648.5, 517.7, 324.8, 138.2, 26.1, 0, 0, 0, 0, 0];
			let dataList2 = [0, 0, 0, 0, 0, 0, 0, 14.9, 15.0, 33.2, 54.9, 59.4, 63.2, 62.8, 62.3, 59.0, 50.5, 30.0, 10.8, 0, 0, 0, 0, 0];

			let thisDays = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

			let dataList3 = [] as number[];
			let dataList4 = [] as number[];
			for (let i = 1; i <= thisDays; i++) {
				let randomVal = Math.random() * (2000 - 1000 + 0.1) + 1000;

				if (i === 10) {
					dataList3.push(5266.7);
					dataList4.push(5266.7);
				} else {
					dataList3.push(5266.7 - randomVal);
					dataList4.push(5266.7 - randomVal);
				}
			}

			let dataList5 = [] as number[];
			let dataList6 = [] as number[];

			for (let i = 2022; i < 2026; i++) {
				let randomVal = Math.floor(Math.random() * (1000 * 12 - 200 * 12 + 50) + 200 * 12);
				if (i === 10) {
					dataList3.push(5266.7 * 12);
					dataList4.push(5266.7 * 12);
				} else {
					dataList3.push(5266.7 * 12 - randomVal);
					dataList4.push(5266.7 * 12 - randomVal);
				}
			}

			if (type === "day") {
				for (let i = 0; i < 24; i++) {
					let hour = i < 10 ? `0${i}` : `${i}`;

					tmpData.push({
						date: `2023-04-26 ${hour}:00`,
						real: dataList1[i],
						predict: dataList2[i],
					});
				}
			} else if (type === "month") {
				for (let i = 1; i <= thisDays; i++) {
					let day = i < 10 ? `0${i}` : `${i}`;

					tmpData.push({
						date: `2023-04-${day}`,
						real: dataList3[i - 1],
						predict: dataList4[i - 1],
					});
				}
			} else if (type === "year") {
				for (let i = 2022; i < 2026; i++) {
					tmpData.push({
						date: `${i}`,
						real: dataList5[i],
						predict: dataList6[i],
					});
				}
			}

			return tmpData;
		},
		// 임시 테스트용
		makeRandom(type: string) {
			let tmpData = [] as siteDataForm[];

			const randomVal = Math.floor(Math.random() * (20 - 5 + 1) + 5);

			if (type === "day") {
				for (let i = 0; i < randomVal; i++) {
					let hour = i < 10 ? `0${i}` : `${i}`;

					tmpData.push({
						date: `2023-04-26 ${hour}:00`,
						real: Math.floor(Math.random() * (50 - 10 + 1) + 10),
						predict: Math.floor(Math.random() * (50 - 10 + 1) + 10),
					});
				}
			} else if (type === "month") {
				for (let i = 0; i < randomVal; i++) {
					let hour = i < 10 ? `0${i}` : `${i}`;

					tmpData.push({
						date: `2023-04-26 ${hour}:00`,
						real: Math.floor(Math.random() * (50 - 10 + 1) + 10),
						predict: Math.floor(Math.random() * (50 - 10 + 1) + 10),
					});
				}
			} else if ((type = "year")) {
				for (let i = 0; i < randomVal; i++) {
					let hour = i < 10 ? `0${i}` : `${i}`;

					tmpData.push({
						date: `2023-04-26 ${hour}:00`,
						real: Math.floor(Math.random() * (50 - 10 + 1) + 10),
						predict: Math.floor(Math.random() * (50 - 10 + 1) + 10),
					});
				}
			}

			return tmpData;
		},

		async getSiteAlarm(siteName: string, alarmType: string, startDate: string, endDate: string) {
			const res = await useCustomFetch<AlarmInfo>(`/api/v1/sites/alarms`, { method: Method.GET, query: { siteName, alarmType, startDate, endDate } });

			return res;
		},
		async getengSourceProduction(
			engSource: string,
			searchDateType: number,
			startDate: string,
			endDate: string,
			siteName: string | undefined = undefined,
			cid: string | undefined = undefined
		) {
			const res = await useCustomFetch<PowerProductionInfo>(`/api/v1/m/prod/info`, { method: Method.GET, query: { siteName, cid, engSource, searchDateType, startDate, endDate } });

			return res.data.value as PowerProductionInfo;
		},
		async getProductionByOwner(
			engSource: string,
			analysisType: string,
			searchDateType: number,
			startDate: string,
			endDate: string,
			city: string | undefined = undefined,
			sigungu: string | undefined = undefined,
      siteName: string | undefined = undefined,
		) {
			const res = await useCustomFetch<ProductionInfoByOwner[]>(`/api/v1/productions-by-owner`, {
				method: Method.GET,
				query: { engSource, analysisType, searchDateType, startDate, endDate, city, sigungu, siteName },
			});

			return res.data.value as ProductionInfoByOwner[] ?? [];
		},
	},
});
