import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

export default defineNuxtPlugin((nuxtApp) => {
    // 규칙 정의
    defineRule('required', required);
    defineRule('email', email);
    defineRule('min', min);
    defineRule('password', (value: string) => {
        // if (value.length < 8) {
        //     return '비밀번호는 8자 이상이어야 합니다.';
        // }

        // if (!/[0-9]/.test(value)) {
        //     return '비밀번호는 숫자를 포함해야 합니다.';
        // }

        // if (!/[a-zA-Z]/.test(value)) {
        //     return '비밀번호는 영문을 포함해야 합니다.';
        // }
        if(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/g.test(value) === false) {
            return '비밀번호는 영문, 숫자, 특수문자를 포함해 8자 이상이어야 합니다.';
        }

        return true;
    });

    defineRule('passwordConfirm', (value: string, [target]: string) => {
        if (value !== target) {
            return '비밀번호가 일치하지 않습니다.';
        }

        return true;
    });

    defineRule('phone', (value: string) => {
        if (!/^\d{3}-\d{3,4}-\d{4}$/.test(value)) {
            return '휴대폰 번호 형식이 올바르지 않습니다.';
        }

        return true;
    });

    defineRule('title', (value: string) => {
        if(value.length > 50 || value.length < 1) {
            return '제목은 1자 이상 50자 이하로 입력해주세요.';
        }
        return true;
    });

    defineRule('siteSearch', (value: string, [target]: string) => {
        if(value !== target) {
            return '일치하는 사이트가 없습니다.';
        }

        return true;
    });

    defineRule('contents', (value: string) => {
        if(value.length > 500 || value.length < 1) {
            return '내용은 1자 이상 500자 이하로 입력해주세요.';
        }
        return true;
    });

    defineRule('strMaxLength', (value: string, [length]: string) => {
        if(value.length > Number(length)) {
            return `최대 ${length}자까지 입력 가능합니다.`;
        }
        return true;
    });

    defineRule('id', (value: string) => {
        if(value.length > 20 || value.length < 4) {
            return '아이디는 4자 이상 20자 이하로 입력해주세요.';
        }
        return true;
    });

    defineRule('nonKorean', (value: string) => {
        if(/^[가-힣]+$/.test(value)) {
            return '한글은 입력할 수 없습니다.';
        }
        return true;
    });

    // 기본 메시지 정의
    configure({
        generateMessage: (ctx) => {
            const messages: Record<string, string> = {
                required: `${ctx.label}은(는) 필수 입력 항목입니다.`,
                email: `${ctx.value}은(는) 유효한 이메일 주소가 아닙니다.`,
                min: `${ctx.field}은(는) ${ctx.rule && ctx.rule.params ? ctx.rule.params.length : undefined}자 이상이어야 합니다.`,
            };

            return ctx.rule && messages[ctx.rule.name as string] ? messages[ctx.rule.name as string] : `${ctx.field}은(는) 유효하지 않습니다.`;
        },
        validateOnChange: true,
        validateOnInput: true,
    });

    // 컴포넌트 등록
    nuxtApp.vueApp.component('VField', Field);
    nuxtApp.vueApp.component('VForm', Form);
    nuxtApp.vueApp.component('ErrorMessage', ErrorMessage);
});