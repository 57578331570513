export enum Method {
	GET = "GET",
	POST = "POST",
	PATCH = "PATCH",
	PUT = "PUT",
	DELETE = "DELETE",
	HEAD = "HEAD",
	OPTIONS = "OPTIONS",
	CONNECT = "CONNECT",
	TRACE = "TRACE",
}

export enum UserType {
  Admin = "admin",
	Owner = "owner",
	Manager = "manager",
}

export enum UserAuthGroup {
	Admin = 1, 				 				//관리자 (TheKIE)
	Owner = 2, 				 				//RTU 소유자 (고객)
	Contractor = 3, 	 				//시공업체 담당자
	RtuManufact = 4, 	 				//RTU업체 담당자 (TheKIE)
	SafetyManager = 5, 				//안전관리 담당자
	HostBizOperator = 6, 	  	//주관사업자 (공기업/ 지자체 등의 지원사업주관기관)
	PrivateBizOperator = 7, 	//민간사업자
}

/** 토큰 용도 타입 */
export enum TokenType {
	Access,											//액세스 토큰
	Refresh,										//리프레쉬 토큰
	ChangePass,									//최초 로그인 비밀번호 번경용 토큰
}

export enum CompanyKind {
	PV = "pv",
	SolarHeat = "solarheat",	
	GeoHeat = "geoheat",	
	Fuel = "fuel",	
	WF = "wf",
	ESS = "ess",
	Rtu = "rtu",
	Safety = "safety",	
	PublicBiz = "publicbiz",		
	PrivateBiz = "privatebiz",		
}

export enum CustomHeaderKeys {
	UserHeader = "user-header",	
}

export enum TermType {
	Service = "SERVICE",
	Privacy = "PRIVACY",
}

export enum TermStatus {
	Active = "Active",
	Inactive = "Inactive",
}

export enum AccountStatusType {
	PIN_AUTH = "pinAuth",
	ACTIVE = "active",
	LOCK = "lock",
	LEAVE = "leave",
	HOLD = "hold"
}

export enum ConnKind {
	Mobile = "mobile",
	PC = "pc",
}

export enum LoginKind {
	Login = "login",
	Logout = "logout",
}

export enum LoginResult {
	Fail = "fail",
	Success = "success",
}

export enum PinType {
	SingUp,
	ResetPass,
}

export enum UseYn {
	Y = "Y",
	N = "N",
}

export enum TokenType {
	BEARER = "Bearer",
}

export enum DateUnit {
	Day = "days",
	Month = "months",
	Year = "years",
	Hour = "hours",
	Minute = "minutes",
	Second = "seconds",
	Date = "date",
}

export enum DateFormatKind {
	YYYY = "YYYY",
	YYYY_MM = "YYYY-MM",
	YYYY_MM_DD = "YYYY-MM-DD",
	YYYY_MM_DD_HH = "YYYY-MM-DD HH",
	YYYY_MM_DD_HH_mm = "YYYY-MM-DD HH:mm",
	YYYY_MM_DD_HH_mm_ss = "YYYY-MM-DD HH:mm:ss",
	YYYYMMDDHHmmss = "YYYYMMDDHHmmss",
}

// export enum EngSystemCompType {
//   Module,
//   Inverter,
//   Collector,
//   DiffController
// }

export enum DistributionKind {
	SinglePhase,
	ThreePhase,
}

export enum EngSource {
	PV = "10",
	SolarHeat = "20",
	Geothermal = "30",
	WF = "40",
	FuelCell = "60",
	ESS = "70",
}

export enum SearchDateType {
	Hourly,
	Daily,
	Monthly,
}

export enum MapSearchType {
	OwnerName = "O",
	EquipCompanyName = "E",
	MonitoringCompanyName = "M",
	SafetyCompanyName = "S",
	BizCompanyName = "B",
}

export enum ChartValueType {
	Prod = "prod", //발전량
	Eff = "eff", //발전효율
	Prev = "prev", //전일 발전량
	Predict = "predict", //예측 발전량
}

export enum ChartValueTypeName {
	Prod = "발전량", //발전량
	Eff = "발전효율", //발전효율
	Prev = "전일 발전량", //전일 발전량
	Predict = "예측 발전량", //예측 발전량
}

// export enum OpStatusType {
//   PV = "10",
// }

export enum WeatherConditionType {
	Unknown, //알수 없음
	Clear = 1, //맑음
	Atmosphere_Fog, //대기중 안개
	Atmosphere_Sand, //대기중 모래
	Atmosphere_Tornado, //강풍
	Clouds_100 = 4, //구릅 100
	Clouds_25 = 3, //구름 25
	Drizzle, //이슬비
	Rain = 5, //비
	Snow = 6, //눈
	Thunderstorm, //천둥번개
}

export enum SigninHeaderKind {
	UserDeviceId = "userdeviceid",
	AppId = "appid",
	AppVer = "appver",
	PushRegId = "pushregid",
}

export enum EquipEventStatusKind {
	All = "all",							//전체 (장애 + 조치)
	Failure = "failure",			//장애
	FailOver = "failover",		//조치 - (정상)
}

export enum AlarmType {
	All = "all",							//전체
	Equip = "equip",						//설비
	Etc = "etc",							//기타
}

export enum FailOverStatus {
	Confirmed = "confirmed",
	Processing = "processing",
	Completed = "completed",	
}

export enum EventProcessKind {		
	All,
	Occur,
	Confirmed,
	Processing,
	Completed
}

export enum PushMessageType {	
	EquipFault = "10",			//설비 고장
	EquipFailover = "11",		//설비 조치
	RtuFault = "20",				//RTU 고장
	RtuFailover = "21",			//RTU 조치
	OwnerReport = "30",			//설치자 리포트
	Qna = "70",							//Qna 답글용	
	AD = "80",							//광고
	Notice = "90",					//공지사항	
}

export enum PushAction {
	NoAction = "10",
	MoveWebPage = "20",
	MoveAppPage = "30",
}

export enum EquipFaultStatus {
	Normal = "normal",
	Fault = "fault",
	Down = "down",
}


export enum pvRawDataType {
	"PV 전압" = "dc_inputv",
	"PV 전류" = "dc_inputa",
	"PV 출력" = "dc_input_power",
	"출력 전압(V)" = "ac_gridv",
	"출력 전류(A)" = "ac_grida",
	"계통 R상(RS선간) 전압" = "ac_gridvr",
	"계통 S상(ST선간) 전압" = "ac_gridvs",
	"계통 T상(TR선간) 전압" = "ac_gridvt",
	"계통R상 전류" = "ac_gridar",
	"계통S상 전류" = "ac_gridas",
	"계통T상 전류" = "ac_gridat",
	"현재 출력" = "ac_grid_power",
	"역률" = "pf",
	"주파수" = "freq",
	"누적발전량" = "totalProdKWH",
	"고장 여부" = "faultStatus",
}


export enum pvRawDataTypeSingle {
	"PV 전압" = "dc_inputv",
	"PV 전류" = "dc_inputa",
	"PV 출력" = "dc_input_power",
	"출력 전압(V)" = "ac_gridv",
	"출력 전류(A)" = "ac_grida",
	"현재 출력" = "ac_grid_power",
	"역률" = "pf",
	"주파수" = "freq",
	"누적발전량" = "totalProdKWH",
	"고장 여부" = "faultStatus",
}


export enum pvRawDataTypeThree {
	"PV 전압" = "dc_inputv",
	"PV 전류" = "dc_inputa",
	"PV 출력" = "dc_input_power",
	"계통 R상(RS선간) 전압" = "ac_gridvr",
	"계통 S상(ST선간) 전압" = "ac_gridvs",
	"계통 T상(TR선간) 전압" = "ac_gridvt",
	"계통R상 전류" = "ac_gridar",
	"계통S상 전류" = "ac_gridas",
	"계통T상 전류" = "ac_gridat",
	"현재 출력" = "ac_grid_power",
	"역률" = "pf",
	"주파수" = "freq",
	"누적발전량" = "totalProdKWH",
	"고장 여부" = "faultStatus",
}

export enum geoRawDataType {
	"전압" = "inputv",
	"전류" = "inputa",
	"출력" = "input_power",
	"히트펌프-열생산량" = "hp_prod",
	"히트펌프-누적생산량" = "hp_total_prod_kwh",
	"히트펌프-누적전력사용량" = "hp_total_usage_kwh",
	"히트펌프-상태값" = "hp_status",
	"히트펌프-지열수 입구 온도" = "hp_geowater_ent_temp",
	"히트펌프-지열수 출구 온도" = "hp_getwater_exit_temp",
	"히트펌프-냉난방 입구 온도" = "hp_hc_ent_temp",
	"히트펌프-냉난방 출구 온도" = "hp_hc_exit_temp",
	"히트펌프-냉온수 유량" = "hp_chwater_flow",
	"부하측-입구온도" = "load_ent_temp",
	"부하측-출구온도" = "load_exit_temp",
	"부하측-사용유량값" = "load_usageflow",
	"부하측-누적사용열량값" = "load_total_usagecal",
	"축열조-급수온도" = "htank_watersupply_temp",
	"축열조-급탕온도" = "htank_hwatersupply_temp",
	"축열조-사용유량값" = "htank_usageflow",
	"축열조-누적사용열량값" = "htank_total_usagecal",
	"고장 여부" = "faultStatus",
}

export enum solarRawDataType {
	"집열기 입구 온도" = "collectorEntranceTemp",
	"집열기 출구 온도" = "collectorExitTemp",
	"축열조 상부 온도" = "htank_top_temp",
	"축열조 하부 온도" = "htank_bottom_temp",
	"유량값" = "flowRate",
	"누적생산량" = "totalProdKCAL",
	"급수 배관 온도" = "waterPipeTemp",
	"급탕 배관 온도" = "hwaterPipeTemp",
	"소비 유량값" = "consumptionFlow",
	"소비 누적사용열량" = "totalUsageKCAL",
	"고장 여부" = "faultStatus",
}

export enum NativeAction {
	PermissionCheckBio = "PermissionCheckBio",
	PermissionCheckLocation = "PermissionCheckLocation",
	PermissionCheckCamera = "PermissionCheckCamera",
	PermissionCheckMedia = "PermissionCheckMedia",
	EnableBio = "EnableBio",
	DisableBio = "DisableBio",
	HapticClick = "HapticClick",
	HapticLong = "HapticLong",
	DontSeeIntro = "DontSeeIntro",
	Login = "Login",
	Dev = "Dev",	
}

export enum PermissionType {
	Camera = "Camera",
	Media = "Media",
}

export enum PermissionStatus {
	Unknown = 0,
	Denied = 1,
	Disabled = 2,
	Granted = 3,
	Restricted = 4,
	Limited = 5,
}

export enum IsYnNum {
	No = "0",
	Yes = "1",
}

export enum OpStatus {
	Down = "down",
	Fault = "fault",
	Normal = "normal",
	Preparing = "preparing",
	Warning = "warning",
}

export enum EquipRtuKind {
	Equip = "equip",
	Rtu = "rtu",
}

export enum PushMsgSendStatus {
	Ready = "00",
	Completed = "10",
}

export enum PushMsgSendResult {
	Success = "10",
	Fail = "20"
}

export enum AreaKind {
	Country = "country",
	City = "city",
	Sigungu = "sigungu",
	Dong = "dong"
}

/** TOC 공통 검색어 입력 타입  */
export enum SearchKind {
	Cid = "10",
	OwnerName = "20",
	SiteName = "30",
	MngName = "40"
}

/** TOC 검색바 있는 화면 종류 **/
export enum tocSearchBarKind {
	//설비정보
	eqMap = "equipMap",						//설비정보 > 발전설비현황
	//운영정보
	eqList = "equipList",					//운영정보 > 발전설비정보
	eqSite = "equipSite",					//운영정보 > 발전사이트정보
	eqKWH = "equipKWH",						//운영정보 > 발전량정보
	//통계분석
	ownerProd = "ownerProd",				//통계분석 > 설치자별 생산량
	ownerCompare = "ownerCompare",	//통계분석 > 설치자간 비교
	areaCompare = "areaCompare",		//통계분석 > 운영지역간 비교
	//이벤트관리
	eqEvent = "equipEvent",					//이벤트관리 > 이벤트처리정보
}

export enum parameterName{
  engSource = '에너지원',
  prjKind = '사업종류',
  prjRegYear = '사업연도',
  equipStatus = '설비상태',
  buildingUsage = '건물종류',
  userStatus = '회원상태'
}