import { Device } from "@nuxtjs/device/dist/runtime/types";
import { Method } from "~/enums";

export const useDeviceStore = defineStore("deviceStore", {
	state: () => ({
		device: "" as string,
		deviceInfo: null as Device | null,
		deviceCheckList: ["isMobile", "isDesktop"],
    defaultDeviceInfo: null as any,
		appInfo: null as any,
	}),

	actions: {
		setDevice() {
			const deviceInfo = useDevice();
			this.deviceInfo = deviceInfo;
      this.defaultDeviceInfo = deviceInfo;
			let trueDevices = [];
			for (const [key, value] of Object.entries(deviceInfo)) {
				if (this.deviceCheckList.includes(key) && value === true) {
					trueDevices.push(key);
				}
			}

			this.device = trueDevices[0];

			// useLocalStorage("intro", deviceInfo);

			// this.device = isMobile
			return deviceInfo;
		},
		async getAppInfo(appId: string) {
			const res = await useCustomFetch(`/api/v1/apps/${appId}`, { method: Method.GET });
			this.appInfo = res.data.value;
			return res.data.value;
		},
	},
});
