import { AppPush } from "@prisma/client";
import { EngSource, Method } from "~/enums";
import { ContractInfo } from "~/models/dto-types";
import { AlarmsDto } from "@/models/dto-types";
import { useLocalStorage } from "@vueuse/core";

export const useEtcStore = defineStore("etcStore", {
	state: () => ({
		contracts: useLocalStorage("contracts", {}),
		alarmSetting: useLocalStorage("alarmSetting", {} as AppPush),
		alarmCount: useLocalStorage("alarmCount", 0),
		isCompanyFirstMap: useLocalStorage("isCompanyFirstMap", true),
		isCompanyFirstEquipList: useLocalStorage("isCompanyFirstEquipList", true),
		isOwnerFirstStatus: useLocalStorage("isOwnerFirstStatus", true),
    loading: false,
    loadingInterval: null as null | NodeJS.Timeout,
		pdfSelectDate: "" as string,
		csTitle: "",
	}),
	// hydrate(state, initialState) {
	// 	state.contracts = useLocalStorage("contracts", initialState.contracts);
	// 	state.alarmSetting = useLocalStorage("alarmSetting", initialState.alarmSetting);
	// 	state.alarmCount = useLocalStorage("alarmCount", initialState.alarmCount);
	// 	state.isCompanyFirstMap = useLocalStorage("isCompanyFirstMap", initialState.isCompanyFirstMap);
	//   state.isCompanyFirstEquipList= useLocalStorage("isCompanyFirstEquipList", initialState.isCompanyFirstEquipList);
	// },
	actions: {
    setLoading(){
      // if(this.loadingInterval !== null){
      //   clearInterval(this.loadingInterval);
      //   this.loadingInterval = setInterval(() => {
      //     this.loading = false
      //     this.loadingInterval = null
      //   }, 1000)

      //   return;
      // }

      // this.loading = true;

      // if(this.loading === true){
      //   this.loadingInterval = setInterval(() => {
      //     this.loading = false
      //     this.loadingInterval = null
      //   }, 1000)
      // }
    },
		setCsTitle(title: string) {
			this.csTitle = title;
		},
		setFirstMap(value: boolean) {
			this.isCompanyFirstMap = value;
		},
		setFirstEquipList(value: boolean) {
			this.isCompanyFirstEquipList = value;
		},
		setOwnerFirstStatus(value: boolean) {
			this.isOwnerFirstStatus = value;
		},
		async getContracts(siteId: string) {
			const res = await useCustomFetch<ContractInfo[]>("/api/v1/etc/contracts", { method: Method.GET, query: { siteId: siteId} });
			if (res.data.value === null) {
				return [];
			}

			return res.data.value as ContractInfo[];
		},
		async getEquipAlarm(userSeq: string, alarmType: string, size: number, lastMessageSeq: number | undefined = undefined) {
			const res = await useCustomFetch<AlarmsDto[]>(`/api/v1/alarms`, { method: Method.GET, query: { userSeq, alarmType, size, lastMessageSeq } });

			return res.data.value;
		},
		async getAlarmSetting() {
			const res = await useCustomFetch<AppPush>(`/api/v1/alarm/settings`, { method: Method.GET });
			this.alarmSetting = res.data.value as AppPush;

		},
		async getAlarmCount() {
			const res = await useCustomFetch<Number>(`/api/v1/alarm/count`, { method: Method.GET });

			this.alarmCount = res.data.value as number;

			return res.data.value as number;
		},
		async setAlarmSetting(appPush: any) {
			const res = await useCustomFetch(`/api/v1/alarm/settings`, { method: Method.PUT, body: appPush });
      
      const parseSetting = JSON.parse(appPush);

      for(let i = 0; i < Object.keys(parseSetting).length; i++){
        let key = Object.keys(parseSetting)[i];
        if(this.alarmSetting[key as keyof AppPush] !== parseSetting[key]){
          this.alarmSetting[key as keyof AppPush] = parseSetting[key];
        }
      }
      
      // this.alarmSetting = JSON.parse(appPush);
			return res.data.value;
		},
		async getImageDownload(attachSeq: number) {
			const res = await useCustomFetch(`/api/v1/test/image-download`, { method: Method.GET, query: { attachSeq } });

			return res.data.value;
		},
	},
});
