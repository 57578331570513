import { UserDeviceInfo } from "@/models/dto-types";
import CommonUtils from "@/utils/common-utils";
import { useUserStore } from "@/store/userStore";
import { NativeAction, PermissionStatus } from "@/enums";

export default defineNuxtPlugin((nuxtApp) => {

  const userStore = useUserStore();

  //생체 인증 설정을 위한 글로벌 함수 (App -> web)
  // @ts-ignore 
  window.changedBioLock = (isBioLock: boolean) => {

    //스토어에 생체 인증 설정 상태를 업데이트 한다.
    userStore.setBioLock(isBioLock);
  };

  // @ts-ignore 
  window.updatePermission = (permissionType: PermissionType, permissionStatus: PermissionStatus) => {

    userStore.setPermission(permissionType, permissionStatus);
    alert(`permission => ${permissionType}, isDenied => ${permissionStatus}`);    
  }



  // @ts-ignore 
  window.logout = async () => {

    alert('Logout');
    //스토어에 생체 인증 설정 상태를 업데이트 한다.
    await userStore.logout();
  };

  // @ts-ignore 
  window.logout2 = async () => {

    alert('Logout2');
  };

  return {
    provide: {

      //TODO: 추후 리팩토링시 App의 CustomWebView 수정하여 안드로이드와 iOS 호출 방식 일원화 필요.	
      invokeNativeAction: (action: NativeAction) => {

        try {
          // @ts-ignore
          jsBridge?.invokeAction(action);
          
        } catch {
          // @ts-ignore
          //invokeAction(action);
          window.webkit.messageHandlers.invokeAction.postMessage(action);
        }
      },

      invokeNativeDownloadAction:(fileName: string, data: string) => {

        try {
          // @ts-ignore
          jsBridge?.downloadAction(fileName, data);
        } catch {
          // @ts-ignore
          //downloadAction({ fileName: fileName, data: data });
          window.webkit.messageHandlers.downloadAction.postMessage({ fileName: fileName, data: data });
        }
      }
    }
  }
});