import { useUserStore } from "~/store/userStore";
import { watch } from "vue";

export default defineNuxtPlugin((nuxtApp) => {
	const route = useRoute();
	const router = useRouter();
	const userStore = useUserStore();

	// nuxtApp.hook("app:created", () => {
	// 	if (process.env.NODE_ENV === "production") {
	// 		if (route.fullPath.includes("swagger-ui")) {
	// 			router.replace("/mobile/error-page");
	// 		}
	// 	}
	// });

	//   nuxtApp.hook("page:start", () => {}),
	// 	nuxtApp.hook("app:mounted", async () => {
	// 		if (
	// 			!route.fullPath.includes("/login") &&
	// 			!route.fullPath.includes("swagger-ui") &&
	// 			!(route.fullPath === "/") &&
	// 			!route.fullPath.includes("/intro") &&
	// 			!route.fullPath.includes("/term") &&
	// 			!route.fullPath.includes("finduser") &&
	// 			!route.fullPath.includes("logout") &&
	// 			!route.fullPath.includes("/signin") &&
	// 			!route.fullPath.includes("/privacy-policy") &&
	// 			!route.fullPath.includes("/error-page")
	// 		) {
  //       console.log("::: lifecycle refresh token :::");
	// 			// 경로가 로그인, swagger-ui, 시작 페이지, 소개, 약관, 사용자 찾기 페이지가 아닌 경우에만 refreshToken 호출
	// 			await userStore.refreshToken();
	// 		}
	// 	});

	// watch(
	// 	() => userStore.apiTokenInfo.accessToken,
	// 	async (token) => {
	// 		// 경로가 로그인, swagger-ui, 시작 페이지, 소개, 약관, 사용자 찾기 페이지가 아닌 경우에만 refreshToken 호출
	// 		if (
	// 			!route.fullPath.includes("/login") &&
	// 			!route.fullPath.includes("swagger-ui") &&
	// 			!(route.fullPath === "/") &&
	// 			!route.fullPath.includes("/intro") &&
	// 			!route.fullPath.includes("/term") &&
	// 			!route.fullPath.includes("finduser") &&
	// 			!route.fullPath.includes("logout") &&
	// 			!route.fullPath.includes("/signin") &&
	// 			!route.fullPath.includes("/privacy-policy") &&
	// 			!route.fullPath.includes("/error-page")
	// 		) {
	// 			// 엑세스 토큰이 "" 인 경우 refreshToken 호출
  //       console.log("::: lifecycle refresh token :::");

  //       console.log("::: token ::: ", token )
	// 			if (token === "") {
	// 				await userStore.refreshToken();
	// 			}
	// 		}
	// 	}
	// );
});
