import { City, CommonCode } from "@prisma/client";
import { Method } from "~/enums";
import { CityDto, CodeDto, DongDto, SigunguDto } from "~/models/dto-types";
import { useLocalStorage } from "@vueuse/core";
import { useEquipStore } from "./equipStore";

interface allCommonCode {
	[x: string]: CommonCode[];
}


export const useCommonCodeStore = defineStore("commonCodeStore", {
	state: () => ({
		codeCategories: useLocalStorage("codeCategories", [] as string[]),
		allCommonCode: useLocalStorage("allCommonCode", {} as allCommonCode),
    mapCommonCode: useLocalStorage("mapCommonCode", {} as Map<string, Map<string, CodeDto>>),
		cities: useLocalStorage("cities", [] as CityDto[]),
		sigungus: useLocalStorage("sigungus", [] as SigunguDto[]),
		dongs: useLocalStorage("dongs", [] as DongDto[]),
	}),

	getters: {},
	actions: {
    init(){
      


    },

		getEngSourceName(engSource: string) {
			let name = "pv";

			let engSourceInfo = this.getMapCode("engSource", engSource);
			switch (engSourceInfo?.codeName) {
				case "태양광": {
					name = "pv";
					break;
				}
				case "태양열": {
					name = "solarheat";
					break;
				}
				case "지열": {
					name = "geo";
					break;
				}
				case "연료전지": {
					name = "fuelcell";
					break;
				}
				case "풍력": {
					name = "wf";
					break;
				}
				case "ESS": {
					name = "ess";
					break;
				}
				default: {
					break;
				}
			}

			return name;
		},

		async getCodeCategories() {
			const res = await useFetch("/api/v1/common/code-categories", { method: Method.GET });
			// this.device = isMobile

			this.codeCategories = res.data.value as string[];
		},
		getMapCode(category: string, cd: string) {
      if (!this.mapCommonCode.has(category)) return;

      if(cd){
        return this.mapCommonCode?.get(category)?.get(cd.toLowerCase() as string) as CodeDto;
      } 
		},
    getMapCodeList(category: string) {
			if (!this.mapCommonCode.has(category)) return;
      
      return this.mapCommonCode?.get(category) as Map<string, CodeDto>;
		},
    getMapCodeToArray(category: string){

      let result = Array.from(this.getMapCodeList(category) as Map<string, CodeDto>, ([key,value]) => ({key, value}));

      return result;
    },
		async getInitAllCodeMap() {
			const res = await useFetch<CommonCode[]>("/api/v1/common/code", { method: Method.GET });
			const categoriesData = res.data.value as CommonCode[];

      this.mapCommonCode = new Map();

			for (let i = 0; i < categoriesData.length; i++) {
				if (!this.mapCommonCode.has(categoriesData[i].category)) {
          this.mapCommonCode.set(categoriesData[i].category, new Map());
				}

        this.mapCommonCode.get(categoriesData[i].category)?.set(categoriesData[i].code, categoriesData[i]);
			}

			return this.mapCommonCode;
		},

    

		async getCities() {
      const equipStore = useEquipStore();
      this.cities = []

			const res = await useFetch<City[]>("/api/v1/common/region/cities", { method: Method.GET });

			// for (let i = 0; i < (res.data.value as City[]).length; i++) {
      //   let cityCode = (res.data.value as City[])[i].cityCode


			// 	if (equipStore.plainEquipList.findIndex(v => v.dongCode?.substring(0,2) === cityCode) !== -1) {
			// 		this.cities.push((res.data.value as City[])[i]);
			// 	}
			// }

			this.cities = res.data.value as City[];

			// for(let i = 0; i < this.cities.length; i++){
			//   await this.getSigungu(this.cities[i].cityCode);
			// }

			return this.cities;
		},
    getCode(category: string, cd: string | undefined = undefined) {


			if (!this.allCommonCode[category]) return;

			let result = this.allCommonCode[category].find((v) => v.code.toLowerCase() === cd?.toLowerCase()) as CommonCode;
			// result['eventStatus']

			return result;
		},


		async getSigungu(cityCode: string) {
			const res = await useFetch<SigunguDto[]>(`/api/v1/common/region/cities/${cityCode}/sigungus`);

			this.sigungus = res.data.value as SigunguDto[];

			// for (let i = 0; i < sigungus.length; i++) {
			// 	if (this.sigungus.findIndex((v) => v.sigunguCode === sigungus[i].sigunguCode) === -1) {
			// 		this.sigungus.push(sigungus[i]);
			// 	}
			// }

			// for(let i = 0; i < this.sigungus.length; i++){
			//   this.getDongs(this.sigungus[i].cityCode as string, this.sigungus[i].sigunguCode);
			// }

			return this.sigungus;
		},

		async getDongs(cityCode: string, sigunguCode: string) {
			const res = await useFetch<DongDto[]>(`/api/v1/common/region/cities/${cityCode}/sigungus/${sigunguCode}/dongs`);
			this.dongs = res.data.value as DongDto[];
			return this.dongs;
		},
	},
});
