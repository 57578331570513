import { useCommonCodeStore } from "./commonCodeStore";
import tocRouteList from "@/utils/toc-route_list.json";
import { useUserStore } from "./userStore";
import { CodeDto } from "~/models/dto-types";

interface routeForm {
	key: string;
	unit: string;
	route: string;
}

interface selectRouteForm{
  path: string,
  name: string,
  icon: string,
  child: Array<this>
}

export const useRouteStore = defineStore("routeStore", {
	state: () => ({
		routeList: [
			{
				key: "10",
				unit: "kW",
				route: "pv",
			},
			{
				key: "20",
				unit: "kWh",
				route: "solarheat",
			},
			{
				key: "30",
				unit: "kW",
				route: "geo",
			},
			{
				key: "40",
				unit: "",
				route: "wf",
			},
			{
				key: "60",
				unit: "",
				route: "fuelcell",
			},
			{
				key: "70",
				unit: "",
				route: "ess",
			},
		] as routeForm[],

		tocRouteList: tocRouteList,
    selectTocParentRoute: {} as any,
		selectTocRoute: {} as selectRouteForm,
    additionalRoute: null as any | null,
    selectRoute: {} as routeForm,
    
    selecTocStack: [] as selectRouteForm[]
	}),

	getters: {
		getRouteInfo() {
			return (engSource: string, type: string = "") => {
				let result;
				if (type === "") {
					result = this.routeList.find((v) => v.key === engSource.toString()) as routeForm;
				} else {
					result = this.routeList.find((v) => v.key === engSource.toString() && v.route === type) as routeForm;
				}

				return result;
			};
		},
	},
	actions: {
    routeInit(){
      const route = useRoute();

    },
    setAdditionalRoute(name: string, path: string){
      const userStore = useUserStore();

      this.additionalRoute = {name: name, path: path};

      return this.additionalRoute;
    },
    setTocParentRoute(path: string){
      const userStore = useUserStore();
      let result = this.tocRouteList[userStore.getUserType as keyof typeof this.tocRouteList].find(v => v.child?.find(t => path.includes(t.path)));
      this.selectTocParentRoute = result;
    },
		routeClickEvent(engSource: string) {
			this.selectRoute = this.routeList.find((v) => v.key === engSource.toString()) as routeForm;
		},
		selectTocRouteInfo(data: any) {
      // const userStore = useUserStore();
      
      // let result = this.tocRouteList[userStore.getUserType as keyof typeof this.tocRouteList].find(v => v.child?.find(t => t.name === data.name));
      // this.selectTocParentRoute = result;

			this.selectTocRoute = data;
		},
    RouteTabProvider(userType: string, path:string){
      if(path === '') {
        this.additionalRoute = null;
        return;
      }

      const commonCodeStore = useCommonCodeStore();
      const engSourceList = commonCodeStore.getMapCodeList('engSource') as Map<string, CodeDto>;
      let isIn = '';
    
      engSourceList.forEach(t => {
        if(path.includes(t.alias as string)){
          isIn = t.alias as string;
        }
      })

      if(isIn !== ''){
        this.additionalRoute = '설비상세정보';

      } else {
        this.additionalRoute = null;
      }
    },
    
    returnFindRoute(userType: string, path: string){
      const pathSplit = path.split('/');
      const findTocIndex = pathSplit.findIndex(v => v === 'toc');

      const parentRouteName = pathSplit[findTocIndex + 1];
      const childRouteName = pathSplit[findTocIndex + 2];

      const tocRoute = this.tocRouteList[userType].find(t => t.path.includes(parentRouteName)).child.find(v => v.path.includes(childRouteName));

      return tocRoute;
    },
    
    findRoute(userType: string, path: string){
      const commonCodeStore = useCommonCodeStore();
      // const engSourceList = commonCodeStore;

      const pathSplit = path.split('/');
      const listPath = pathSplit[pathSplit.length - 1];
      // const findTocIndex = pathSplit.findIndex(v => v === 'toc');

      // const parentRouteName = pathSplit[findTocIndex + 1];
      // const childRouteName = pathSplit[findTocIndex + 2];
      
      const tocRoute = this.selectTocParentRoute.child.find(v => v.path.includes(listPath));

      this.selectTocRouteInfo(tocRoute);
    },
		getUnit(engSource: string) {
			return this.routeList.find((v) => v.key === engSource)?.unit;
		},
		makeRoutes() {
			const codeStore = useCommonCodeStore();
      const engSourceCode = codeStore.getMapCodeList('engSource') as Map<string, CodeDto>;
			let data = [] as routeForm[];

      engSourceCode.forEach((value, key) => {
        data.push({
          key: key,
          unit: "",
          route: value.alias as string
        })
      })

      this.routeList = data;

		},
	},
});
