import { useDeviceStore } from "@/store/deviceStore";
import type { UserDeviceInfo } from "~~/src/models/dto-types";
export default defineNuxtRouteMiddleware((to, from) => {

  const deviceStore = useDeviceStore();
	deviceStore.setDevice();

	const isMobile = deviceStore.deviceInfo?.isMobile;

	const deviceInfo = JSON.parse(window.localStorage.getItem("userDeviceInfo") as string) as UserDeviceInfo;
  // console.log(deviceInfo);
  if(!deviceInfo) window.localStorage.setItem("userDeviceInfo", JSON.stringify({} as UserDeviceInfo));
  // console.log(isMobile, deviceInfo);

  const IsShowIntro = deviceInfo?.IsShowIntro;
  // console.log(IsShowIntro && to.query.intro !== "false" && window.localStorage.getItem("autoLogin") !== "true");

  const isAutoLoginCheck = ref(window.localStorage.getItem("autoLogin") === 'true' ? 'true' : 'false');



	if (isMobile) {
		if ((IsShowIntro === true || IsShowIntro === undefined) && to.query.intro !== "false" && isAutoLoginCheck.value !== "true") {
			// if (!from.fullPath.includes("login")) {
			// if (to.query.intro !== "false") {
			return navigateTo("/mobile/user/intro");
		}
	}
});
